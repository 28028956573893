.informatie {
  line-height: 1.7em;
}

.informatie p {
  font-weight: normal;
  margin-bottom: 1.8em;
}

.inleiding {
  color: $inleiding !important;
}

@include media-breakpoint-down(md) {
  .informatie {
    text-align: left;
  }
}
figure.figure-progressive {
  background-color: $light;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
  padding-bottom: 49.6%;
  margin: 0;
  &.figure-square {
    padding-bottom: 100%;
  }
  img {
    position: absolute;
    opacity: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: opacity 0.3s linear;
    &.loaded {
      opacity: 1;
    }
  }

  .img-small {
    filter: blur(50px);
    transform: scale(1);
  }
}
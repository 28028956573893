//standard global brand colors enabled
//use custom variables to override the global settings
$bg-color-parent: $primary;
$bg-color-children: $white;
$text-color: $white;

.sidenav {
  height: 100%;
  width: 90vw;
  position: fixed;
  z-index: 9999;
  top: 0;
  right: -100vw;
  background-color: $bg-color-parent;
  overflow-x: hidden;
  @include transition(0.5s);

  .sidenav-header {
    background-color: $primary;
    height: 62px;
    display: flex;
    justify-content: space-between;
    padding-left: 24px;
    padding-right: 24px;
    align-items: center;
    color: $text-color;
    font-size: 18px;
    z-index: 9;
    position: relative;

    .sidenav-control {
      a {
        color: #fff;
        text-decoration: none;
      }

      span {
        padding: 8px;
        font-size: 34px;
        line-height: normal;
      }

      .searchbtn {
        span {
          transform: rotate(30deg);
          display: inline-block;
        }
      }
    }
  }

  .sidenav-searchbox {
    padding: 8px 24px 8px 24px;
    border-bottom: 1px solid rgba(255,255,255,0.2);
    height: 51px;
    margin-bottom: -51px;
    transform: translateY(-51px);
    @include transition(0.3s);

    input {
      width: 100%;
      padding: 5px 10px;
      border: none;
    }

    &_open {
      margin-bottom: 0;
      transform: translateY(0);
    }
  }

  .sidenav-panels {
    position: relative;
    height: 100%;
  }
  .sidenav-panel {
    background-color: $bg-color-children;
    position: absolute;
    top: 0;
    left: 100%;
    width: 100%;
    @include transition(0.5s);

    &#panel-menu {
      background-color: $primary;
      left: 0;

      li {
        border-bottom: 1px solid rgba(#ffffff,0.2);
      }
      a {
        padding: 8px 50px 8px 24px;
        color: $text-color;
        text-transform: uppercase;
      }
    }

    &_open {
      left: 0;
      &#panel-menu {
        left: -100%;
      }
    }

    .backbtn {
      padding: 16px 0 16px 24px!important;
      height: 60px;
      display: flex!important;
      justify-items: center;
      border-bottom: 1px solid rgba(51,51,51,0.1);
    }

    a {
      padding: 8px 24px 8px 24px;
      display: block;
      color: #333333;

      span.arrow-small {
        margin-right: 6px;
        font-size: 22px;

        &--right {
          margin-right: 0;
          margin-left: 6px;
        }
      }

      span:not(.arrow-small):not(.arrow) {
        display: block;
      }
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      .item-image img {
        display: none;
      }

      li {
        a:hover {
          background-color: rgba(#ffffff,0.2);
        }
      }

      li.has-subitems {
        position: relative;

        ul {
          display: none;
        }
        span.arrow {
          padding: 0 20px;
          position: absolute;
          top: 0;
          right: 0;
          height: 100%;
          width: 50px;
          font-size: 34px;
          line-height: normal;
          display: flex;
          align-items: center;
          flex-direction: column-reverse;
        }
      }

      li.label {
        padding: 8px 24px 8px 24px;
      }

      > a {
        display: none;
      }

      li {
        ul {
          a {
            padding: 8px 0;
          }
        }
      }
    }

    ul > li:not(.label) {
      border-bottom: 1px solid rgba(#333333,0.1);
    }
  }
}

.mobile-menu-active {
  .sidenav {
    right: 0;
  }
}

.sidenav a {
  @include transition(0.3s);
}

@include media-breakpoint-up(md) {
  .sidenav {
    width: 40vw;
  }
}
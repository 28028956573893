@function palette($palette, $tone: 'base') {
        @return map-get(map-get($palettes, $palette), $tone);
}
@function rem-calc($size) { $remSize: $size / 16; @return #{$remSize}rem; }

@mixin scale($ratio) {
        -webkit-transform: scale($ratio);
        -ms-transform: scale($ratio); // IE9 only
        -o-transform: scale($ratio);
        transform: scale($ratio);
}
//@mixin fontSize($size) {
//  font-size: $size; //Fallback in px
//  font-size: calculateRem($size);
//}
.stappen, .vijfstappen {
  margin-top: 3em;
  text-align: center;
}

.stappen p {
  color: white;
}

// CSS voor de icons
.icon {
  margin-bottom: 1.5em;
  width: 80%;
  height: auto;
  border-radius: 50%;
  margin-top: 0;
}

.vijfstappen h2 {
  color: white;
}

.vijfstappen p {
  color: white;
  font-size: 1em;
  font-weight: 400;
  padding: 0 1rem;
}

.stappen h2 {
  color: white;
}

@include media-breakpoint-down(sm) {
  .icon {
    height: 90px;
    width: 90px;
    float: left;
    margin-right: 1em;
  }

  .vijfstappen h2 {
    float: left;
    margin-right: 0.5em;
  }

  .alignment {
    position: relative;
    top: 85px;
  }
}

@include media-breakpoint-down(md){
  .aligncenter-sm {
    align-items: center !important;
  }
}

@include media-breakpoint-down(lg) {
  .stappen, .vijfstappen {
    text-align: left;
  }
}
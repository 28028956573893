ul.nav-desktop {
  list-style: none;
  padding: 0;
  margin: 0;
  position: relative;
  z-index: 99;
  > li {
    display: inline-block;
    &:hover {
      ul {
        display: inline-block;
      }
    }
    a {
      padding: 0.5rem 1rem;
      display: inline-block;
    }
    > ul {
      background-color: transparent;
      position: relative;
      left: 0;
      list-style: none;
      padding: 0 0 2rem 0;
      margin: 0;
      width: 100%;
      z-index: 99999;
      column-count: 3;
      box-shadow: 1px 1px 12px rgba(0,0,0,0.2);
      display: none;

      li {
        padding: 2rem 2rem 0 2rem;
        break-inside: avoid;

        a {
          display: flex;
          color: white;
          text-decoration: none;

          .item-wrapper {
            display: flex;
            flex-direction: column;
            white-space: nowrap;
            justify-content: center;
          }
        }

        &.label {
          display: inline-block;
        }
      }
    }
    > ul li ul {
      padding: 0;
      list-style: none;
      width: 100%;
      column-count: 1;
      li {
        padding: 0;
        a {
          padding: 0.15rem 0;
        }
      }
    }

    .item-image img {
      margin-right: 15px;
      width: 77px;
    }
  }
}

.brand-logo {
  width: auto;
  min-height: 50px;
  height: 50px;
}

@include media-breakpoint-down(md) {

  .brand-logo {
    min-height: 40px;
    height: 40px;
  }

  ul.nav-desktop {
    display: none;
  }
}

// Navbar voor scroll
#navbar {
  top: 0;
  text-transform: uppercase;
  position: absolute;
  width: 100%;
  left: 0;
  @include media-breakpoint-down(lg) {
    display: -webkit-box;
    position: fixed !important;
    top: 0;
    width: 100%;
    z-index: 999 !important;
    transition: all 0.5s;
    &.hideNavbar {
      display: none;
    }
  }
}

// Background color voor responsive navbar
.navbarbackground {
  @include media-breakpoint-down(lg) {
    background-color: $h1;
    opacity:0.9;
    -webkit-box-shadow: 0px 7px 35px -6px rgba(0,0,0,0.50);
    -moz-box-shadow: 0px 7px 35px -6px rgba(0,0,0,0.50);
    box-shadow: 0px 7px 35px -6px rgba(0,0,0,0.50);
  }
}

// Padding voor border-top
.navbar {
  //align-items: flex-end !important;
  @include media-breakpoint-up(xl) {
    //padding-top: 0;
    //padding-bottom: 0;
  }
}

.nav-link, .btn-menu {
  margin-left: 0 !important;
}

.btn-menu {
  @include media-breakpoint-up(xl) {
    margin-left: 1.2em !important;
  }
}

// Underline hidden en color voor menu
.nav-link, .navbar-brand {
  text-decoration: none;
  font-weight: 300;
  font-size: 1.1em;
  color: white !important;
  //padding-bottom: .5rem;
  @include media-breakpoint-down(sm) {
    margin-right: 1em;
  }
}

.nav-link {
  @include media-breakpoint-up(xl) {
    margin-left: 0.7em !important;
  }
  position: relative;
  &:before {
    position: absolute;
    top: -17px;
    left: 50%;
    content: ""; /* This is necessary for the pseudo element to work. */
    display: inline-block; /* This will put the pseudo element on its own line. */
    background-color: transparent; /* This creates the border. Replace black with whatever color you want. */
    height: 6px;
    width: 50px;
    transform: translateX(-25px);
  }
  &.active, &:hover {
    &:before {
      background-color: #df04a4;
      @include media-breakpoint-down(lg) {
        background-color: transparent;
      }
    }
  }
  &.active {
    font-weight: bold;
  }
  @include media-breakpoint-down(md) {
    padding-top: 0.5em;
  }
}

// Spacing boven button in menu
.nav-item > .button {
  margin-top: 1em;
}

// Underline hidden voor dropdown menu
.dropdown-item {
  text-decoration: none;
}

.menubackground {
  @include media-breakpoint-down(xl) {
    background-color: $h1;
    opacity: 0.9;
    -webkit-box-shadow: 0px 7px 35px -6px rgba(0,0,0,0.50);
    -moz-box-shadow: 0px 7px 35px -6px rgba(0,0,0,0.50);
    box-shadow: 0px 7px 35px -6px rgba(0,0,0,0.50);
  }
}

.dropdown-menu {
  margin-left: 1em !important;
  padding-top: 1.4em;
  padding-bottom: 1.4em;
  background-color: rgba(0,0,0,0.15);
  color: white;
  width: 350px;
  @include media-breakpoint-down(lg) {
    background-color: rgba(255, 255, 255, 0);
    border: 0;
    padding-top: 0.9em;
    padding-bottom: 0.9em;
  }
}

.dropdown-item {
  text-transform: initial;
  color: white;
  &:hover {
    color: white;
    background-color: rgba(255, 255, 255, 0);
    font-weight: bold;
  }
}

.dropdown-item.active {
  background-color: transparent;
  color: #fff;
  text-decoration: none;
  font-weight: bold;
}
body {
  min-width: 320px;
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
  background-color: #f7f7f7;
  font-family: 'Open Sans', sans-serif;
  color: #585B5B;
}

section {
  margin: 0;
  padding: 0;
}

h2 {
  color: $heading;
  font-size: rem-calc(34);
  font-weight: 700;
  padding-bottom: 0.7em;
}

h3 {
  color: $inleiding;
  font-size: rem-calc(24);
  font-weight: 700;
  padding-bottom: 0.7em;
}

h4 {
  color: $inleiding;
  font-size: rem-calc(16);
  font-weight: 700;
  padding-bottom: 0.7em;
}

h5,h6 {
  color: $inleiding;
  padding-bottom: 0.7em;
}

// CSS voor a-tag
a {
  text-decoration: underline;
  color: #DF04A4;
  font-weight: bolder;
  &:hover {
    color: #e357ad;
  }
}

// CSS voor p-tag
p {
  font-size: rem-calc(16);
  font-weight: 600;
}

.cc-btn {
  text-decoration: none !important;
}

.btn, .btn-purple, .btn-blue, .btn-menu, .btnunder, .btn-form {
  color: white;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
  border: none;
  padding-left: 2.4em;
  padding-right: 2.4em;
  padding-top: 0.7em;
  padding-bottom: 0.7em;
  font-weight: bold;
  text-decoration: none;
  text-transform: none;
}

// CSS voor paarse buttons
.btn-purple, .btn-menu, .btnunder, .btn-form {
  font-size: rem-calc(18);
  background-image: linear-gradient(to right,rgba(145, 0, 182, 1), rgba(218, 3, 165, 1));
  &:hover {
    background-image: linear-gradient(to right,rgba(218, 3, 165, 1), rgba(145, 0, 182, 1));
    color: white;
  }
}

.btn-menu, .btn-form {
  font-size: rem-calc(15);
  width: auto;
  padding-left: 2.4em;
  padding-right: 2.4em;
  padding-top: 0.5em;
  padding-bottom: 0.6em;
}

.btn-form {
  margin-left: 0 !important;
}

// CSS voor blauwe buttons
.btn-blue {
  font-size: rem-calc(18);
  padding-left: 3.8em;
  padding-right: 3.8em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  border: 1px solid transparent;
  background-image: linear-gradient(to right,rgba(21, 144, 248, 1), rgba(21, 61, 172, 1));
  &:hover {
    background-image: linear-gradient(to right,rgba(21, 61, 172, 1), rgba(21, 144, 248, 1));
    color: white;
  }
}

.btnunder {
  padding-left: 3em;
  padding-right: 3em;
  padding-top: 0.6em;
  padding-bottom: 0.6em;
}

// Background voor Informatie
.informatie-background {
  background-size: cover;
  background-position: right;
  padding-top: 5em;
  padding-bottom: 5em;
}

// Background voor Offerte en Stappen
.offerte-background, .stappen-background {
  background-size: cover;
  background-position: center;
  color: white;
}

.stappen-background {
  padding-top: 2em;
  padding-bottom: 5em;
}

.offerte-background {
  padding-top: 7em;
  padding-bottom: 7em;
}

// Background voor footer
.footer-background {
  background-size: cover;
  background-position: top;
  padding-top: 1em;
  color: #153dac;
}

// Background voor header
.headermenu-background {
  //background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  display: -webkit-box;
  //padding-top: 0.8em;
  color: white;
  @media (min-width: 1999px) {
    background-position: top;
  }
}

// Color voor H2-tag in Offerte
.offerte-background h2 {
  color: white;
}

// Background met een grijze kleur
.backgroundgray {
  background-color: #e7e9f1;
  padding-top: 5em;
  padding-bottom: 5em;
}

.is-hidden{ display: none; }

.owl-item {
  .img-wrapper {
    padding: 0;
    img {
      margin-left: auto;
      margin-right: auto;
    }
  }
}

@include media-breakpoint-down(sm) {
  .btn-blue {
    width: 100%;
  }
  .headermenu-background {
    height: auto;
    padding-top: 0;
  }
}

@include media-breakpoint-down(md) {
  h2 {
    font-size: 1.9em;
    padding-bottom: 0.4em !important;
  }

  .offerte-background {
    padding-top: 3em !important;
    padding-bottom: 3em !important;
  }

  .headermenu-background {
    height: auto;
  }

  .backgroundgray {
    padding-top: 3em;
    padding-bottom: 3em;
  }
}

@include media-breakpoint-up(md) {
  .footer-background {
    padding-top: 5em;
  }
}

@include media-breakpoint-down(lg) {
  .btn-purple, .btn-menu, .btnunder, .btn-form {
    width: 100%;
    padding-left: 2em !important;
    padding-right: 2em !important;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
  }

  .informatie-background {
    padding-top: 3em;
    padding-bottom: 3em;
    background-position: left;
  }

  .stappen-background {
    padding-top: 1em !important;
    padding-bottom: 1em !important;
  }
}
.slider {
  text-align: center;
  margin-top: 2em;
  &:nth-child(2nd) {
    margin-top: 1em;
  }
}

.owl-carousel .owl-stage {
  display: flex;
  align-items: center;
}

@include media-breakpoint-down(md) {
  .slider {
    margin-top: 0;
  }
}
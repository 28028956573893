.faq {
  margin-top: 5em;
  margin-bottom: 5em;
}

// CSS voor description van de FAQ
.description {
  margin-left: 1.9em;
}

.faq p {
  padding-right: 3em;
  line-height: 1.8em;
  font-weight: 400;
}

// CSS voor de FAQ
.option-heading {
  font-weight: 700;
  font-size: 1.2em;
  color: $heading;
  padding-right: 0.4em;
  cursor: pointer;
  line-height: 1.5em;
}

// Dit zorgt ervoor dat de arrow aan de rechterkant staat
i {
  float: right;
  font-size: 1.2em;
}

@include media-breakpoint-down(sm) {
  .faq {
    text-align: left;
  }

  .option-heading {
    font-size: 0.9em;
  }
}

@include media-breakpoint-down(md) {
  .faq {
    margin-top: 3em;
    margin-bottom: 3em;
  }

  .imgbeside {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 1em;
    margin-top: 2em;
  }

  .description {
    margin-left: 0;
  }
}
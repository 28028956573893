$palettes: (

        green: (
                base: #a5cd39
        ),
        red: (
                base: #d9534f
        ),
        gray: (
                light: #f8f9fa,
                dark: #343a40
        )

);

$primary:       palette(green, base);
$secondary:     palette(red, base);

$light:         palette(gray, light);
$dark:          palette(gray, dark);

$white:    #fff;
$black:    #000;

$theme-colors: () !default;
$theme-colors: map-merge((
        "dunique-green":    palette(green, base)
), $theme-colors);


$h1:            #193CB1;
$inleiding:     #2B3758;
$heading:       #193CB1;
$paragraph:     #585B5B;
$a:             #DF04A4;

// Set a specific jump point for requesting color jumps
$theme-color-interval:      8% !default;

// The yiq lightness value that determines when the lightness of color changes from "dark" to "light". Acceptable values are between 0 and 255.
$yiq-contrasted-threshold:  150 !default;

// Customize the light and dark text colors for use in our YIQ color contrast function.
$yiq-text-dark:             $black;
$yiq-text-light:            $white;
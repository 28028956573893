.headermenu {
  margin-top: 19vw;
  @media (min-width: 1950px) {
    margin-top: 10vw;
    margin-bottom: 22em;
  }
  margin-bottom: 10em;
}

// CSS voor titel van de header
.headermenu h1 {
  color: white;
  font-size: 4rem;
  font-weight: 700;
  line-height: 1em;
  margin-bottom: 0.7em;
}

// Afbeelding naast de titel
.imgheadermenu {
  @media (min-width: 1600px){
    min-width: 680px;
  }
}

@include media-breakpoint-down(sm) {
  .headermenu .col-lg-6 {
    padding-left: 1em;
  }
}

@include media-breakpoint-down(md) {
  .headermenu {
    margin-top: 7em;
    margin-bottom: 0;
  }

  .headermenu h1 {
    font-size: 2em;
  }

  .imgheadermenu {
    margin-left: 0;
    padding-top: 2em;
    width: 35em;
  }
}

@include media-breakpoint-down(lg) {
  .headermenu h1 {
    font-size: 2rem;
  }
}


.headermenu-contentpage {
  margin-top: 13vw;
}

// Titel van de header op de contentpagina
.headermenu-contentpage h1 {
  font-size: 3.3rem;
  font-weight: bold;
  text-align: right;
}

// Background van de contentpagina
.contentpage-background {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  display: -webkit-box;
  color: white;
}

// Background van tweede section op contentpagina
.sectiontwee-background {
  padding-top: 10em;
  padding-bottom: 10em;
  @include media-breakpoint-down(md) {
    padding-top: 3em !important;
    padding-bottom: 2em !important;
  }
}

@include media-breakpoint-down(md) {
  .headermenu-contentpage {
    margin-top: 7em;
    margin-bottom: 0;
  }

  .headermenu-contentpage h1 {
    font-size: 2rem;
    text-align: right;
  }

  .contentpage-background {
    padding-bottom: 3em;
  }
}

@include media-breakpoint-up(lg) {
  .headermenu-contentpage {
    margin-top: 10vw;
    margin-bottom: 8em;
  }
}
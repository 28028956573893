.offerte {
  text-align: center;
}

.offerte p {
  color: white;
  line-height: 1.6em;
  margin-top: 2em;
  margin-bottom: 3em;
  font-size: 1em;
  font-weight: 600;
}

.offerte h2 {
  color: white;
}

@include media-breakpoint-down(sm) {
  .offerte {
    text-align: left;
  }
}

@include media-breakpoint-down(md) {
  .offerte p {
    margin-top: 1em;
    margin-bottom: 2em;
  }
}

.icons {
  margin-bottom: 5em;
}

// CSS voor de Icons
.infoicon {
  margin-top: 8em;
  @media (min-width: 1950px) {
    margin-top: 3em;
  }
  margin-bottom: 0.8em;
  max-width: 70%;
  height: auto;
}

.marginright {
  margin-right: 0;
}

.icons h2 {
  margin-top: 0.4em;
}

@include media-breakpoint-down(md) {
  .icons {
    text-align: center;
    justify-content: center;
    margin-bottom: 3em;
  }

  .infoicon {
    margin-top: 4em;
  }
}

@include media-breakpoint-up(lg) {
  .marginright {
    margin-right: 3em;
  }
}

@include media-breakpoint-up(xl) {
  .marginright {
    margin-right: 6em;
  }
}
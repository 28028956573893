.footer {
  text-align: right;
  line-height: 2em;
  &:last-child {
    text-align: center;
    margin-top: 0.5em;
  }
}

// CSS voor a-tags in de footer
.footer a {
  color: $heading;
  font-weight: 300;
  text-decoration: none;
  &:hover {
    text-decoration: none;
    color: black;
  }
}

.footer p {
  color: $heading;
  font-weight: 300;
  margin-bottom: 0;
}

// CSS voor laatste regel op de pagina
.footer-product {
  color: $heading;
  text-transform: uppercase;
  font-size: rem-calc(14);
  margin-bottom: 2em !important;
  text-align: center;
}

// CSS voor logo in footer
.img-footer {
  width: 9em;
}

@include media-breakpoint-down(md) {
  .footer {
    text-align: left;
  }
}

@include media-breakpoint-down(md) {
  .footer a, .footer p {
    font-size: 0.9em;
  }

  .footer-product {
    font-size: 0.9em;
    margin-top: 2em;
  }

  .img-footer {
    margin-top: 1em;
    margin-bottom: 1em;
  }
}
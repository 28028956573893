.share {
  position: relative;

  .share--title {
    text-align: center;
    position: relative;
    padding: $spacer 0;

    h5 {
      background-color: $white;
      padding: 0 $spacer;
      margin: 0;
      display: inline-block;
    }

    &:before {
      content: "";
      border-top: 1px solid rgba($black,0.4);
      position: absolute;
      top: 50%;
      width: 100%;
      z-index: -1;
      left: 0;

      transform: translateY(-50%);
    }
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    text-align: center;

    li {
      display: inline;
      padding: 0;
      margin-right: 8px;
      text-align: center;

      &:before {
        display: none;
      }

      a {
        color: $black;

        i {
          background-color: transparent;
          display: inline-block;
          margin-right: 4px;

          width: 24px;
          height: 24px;

          text-align: center;
        }

      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .share {
    ul {
      li {
        display: block;
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .share {
    ul {
      li {
        display: inline-block;
      }
    }
  }
}
.news-list {
  margin: 0;

  a.news-list-item {
    position: relative;
    display: block;
    padding: $spacer;
    margin-bottom: 3rem;
    color: inherit;

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      box-shadow: 0 0 30px rgba(0,0,0,.15);
      opacity: 0;
      border-radius: $border-radius;

      @include transition(0.3s);
    }
  }

  a:hover {
    text-decoration: none;
    &:after {
      opacity: 1;
    }
  }
}